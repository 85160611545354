<template>  
    <div class="content-wrapper">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems"/>

        <main class="card p-2" :class="{'opacity-50': loadingData}">
            <section class="infos mb-4">
                <b-row class="mb-2">
                    <b-col lg="5" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">Nome do Ponto de Atendimento*</label>
                        <p class="font-size-14">{{ servicePoint.name }}</p>
                    </b-col>
                    <b-col lg="4" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">Tipo*</label>
                        <p class="font-size-14">{{ servicePoint.type }}</p>
                    </b-col>
                    <b-col lg="1" md="2">
                        <label class="font-weight-bold mb-0 font-size-15">Código*</label>
                        <p class="font-size-14">{{ servicePoint.code }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col lg="5" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">CEP*</label>
                        <p class="font-size-14">{{ servicePoint.cep | VMask('#####-###')}}</p>
                    </b-col>
                    <b-col lg="4" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">Rua/Avenida*</label>
                        <p class="font-size-14">{{ servicePoint.address }}</p>
                    </b-col>
                    <b-col lg="1" md="2">
                        <label class="font-weight-bold mb-0 font-size-15">Número*</label>
                        <p class="font-size-14">{{ servicePoint.number }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col lg="5" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">Complemento</label>
                        <p v-if="servicePoint.complement" class="font-size-14">{{ servicePoint.complement }}</p>
                        <p v-else class="text-danger">-</p>
                    </b-col>
                    <b-col lg="4" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">Bairro*</label>
                        <p class="font-size-14">{{ servicePoint.neighborhood }}</p>
                    </b-col>
                    <b-col lg="1" md="2">
                        <label class="font-weight-bold mb-0 font-size-15">Cidade*</label>
                        <p class="font-size-14">{{ servicePoint.city }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col lg="5" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">Unidade Operacional*</label>
                        <p class="font-size-14">{{ servicePoint.operatingUnit }}</p>
                    </b-col>
                    <b-col lg="4" md="5">
                        <label class="font-weight-bold mb-0 font-size-15">Região do DR*</label>
                        <p class="font-size-14">{{ servicePoint.region }}</p>
                    </b-col>
                </b-row>
            </section>
            <section v-if="canEdit" class="buttons">
                <b-button id="redirect-edit" 
                    class="mr-2" 
                    variant="custom-blue"
                    @click.prevent="redirectToEdit"
                    :disabled="loadingData"
                >
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Editar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BRow, BCol, BButton } from 'bootstrap-vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import { getIdPontoAtendimento, getDescricaoPontoAtendimento } from '@core/utils/store/getStore';
import { setIdPontoAtendimento, setDescricaoPontoAtendimento } from "@core/utils/store/setStore";

export default {
    title: 'Detalhes ponto de atendimento',

    components: {
        PageHeader,
        BRow, BCol, BButton
    },

    data() {
        return {
            pageTitle: 'Visualizar Ponto De Atendimento',
            linkItems: [
                {
                    name: 'Pontos de Atendimento',
                    routeName: 'ponto-atendimento-list'
                },
                {
                    name: 'Ponto De Atendimento',
                    active: true
                }
            ],
            canEdit:false,
            loadingData: true,
            servicePoint: {
                name: null,
                type: null,
                code: null,
                cep: null,
                address: null,
                number: null,
                complement: null,
                neighborhood: null,
                city: null,
                operatingUnit: null,
                region: null
            }
        }
    },
    mounted() {
        if((getIdPontoAtendimento() === null || typeof getIdPontoAtendimento() == 'undefined') && typeof this.$route.params.id_ponto_atendimento == "undefined") {
            return this.redirectToList();
        }else if(typeof this.$route.params.id_ponto_atendimento != "undefined" && this.$route.params.id_ponto_atendimento !== getIdPontoAtendimento()) {
            setIdPontoAtendimento(this.$route.params.id_ponto_atendimento); 
            setDescricaoPontoAtendimento(this.$route.params.descricao); 
        }

        this.linkItems[1].name = getDescricaoPontoAtendimento();
        this.pageTitle = getDescricaoPontoAtendimento();

        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)

        this.loadDataPontoAtendimento();
    },
    methods: {
        loadDataPontoAtendimento() {
            this.$http.get(this.$api.pontoAtendimentoParameter(getIdPontoAtendimento()))
            .then(({ data }) => {
                this.servicePoint.name = data.descricao;
                this.servicePoint.type = data.tipo_ponto_atendimento.descricao;
                this.servicePoint.code = data.codigo;
                this.servicePoint.cep = data.cep;
                this.servicePoint.address = data.endereco;
                this.servicePoint.number = data.numero;
                this.servicePoint.complement = data.complemento;
                this.servicePoint.neighborhood = data.bairro;
                this.servicePoint.city = data.cidade.nome;
                this.servicePoint.operatingUnit = data.unidade[0].descricao;
                this.servicePoint.region = data.unidade[0].regiao.descricao;

                this.loadingData = false
            }).catch(() => {
                this.redirectToList();
            })
        },
        redirectToList() {
            this.$router.push({ name: 'ponto-atendimento-list' });
        },

        redirectToEdit() {
            this.$router.push(
                { 
                    name: 'ponto-atendimento-edit', 
                    params: {
                        id_ponto_atendimento: getIdPontoAtendimento(),
                        descricao: getDescricaoPontoAtendimento()
                    }
                }
            );
        },
        verifyPermissions(action) {
            const permissionUO = this.$can(actions[action], subjects.PONTO_ATENDIMENTO_UO)
            const permissionDR = this.$can(actions[action], subjects.PONTO_ATENDIMENTO_DR)

            return [permissionUO, permissionDR].includes(true);
        }
    }
}
</script>

